import AppBar from "./AppBar";
import FilterInput from "./FilterInput";
import Loading from "./Loading";
import Modal from "./Modal";
import EditDossierModelModal from "./Modal/EditDossierModelModal";
import EditCollaboratorUserModal from "./Modal/EditCollaboratorUserModal";
import FontsDossierModelModal from "./Modal/FontsDossierModelModal";
import GenerateAgain from "./Modal/GenerateAgain";
import PdfViewer from "./Modal/PdfViewer";
import SelectExternalFonts from "./Modal/SelectExternalFonts";
import SendConfirmationModal from "./Modal/SendConfirmationModal";
import RecoverPasswordModal from "./Modal/RecoverPasswordModal";
import { PrivacyPolicyModal } from "./Modal/PrivacyPolicyModal";
import { TermsConditions } from "./Modal/TermsConditions";
import SummaryDossier from "./Modal/SummaryDossier";
import EditCompanyUserModal from "./Modal/EditCompanyUserModal/index";
import SummaryTestModal from "./Modal/SummaryTestModal";
import FontTestLogModal from "./Modal/FontTestLogModal";
import FontStatusDetailsModal from "./Modal/FontStatusDetailModal";
import CollaboratorListModal from "./Modal/CollaboratorsListModal";

const Modals = {
  PdfViewer,
  SelectExternalFonts,
  EditDossierModelModal,
  FontsDossierModelModal,
  EditCollaboratorUserModal,
  EditCompanyUserModal,
  SendConfirmationModal,
  RecoverPasswordModal,
  GenerateAgain,
  PrivacyPolicyModal,
  TermsConditions,
  SummaryDossier,
  SummaryTestModal,
  FontTestLogModal,
  FontStatusDetailsModal,
  CollaboratorListModal,
};

export { AppBar, Loading, Modal, Modals, FilterInput };
