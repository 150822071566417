import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;

  .MuiOutlinedInput-input {
    padding: 0.5rem;
  }
`;
