import { CompanyUserContextProps } from "contexts-types";
import { Columns, FilterProps, Props } from "props";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { IUserViewModel } from "viewModels";
import { useAuth } from "./auth";
import { IOrderFieldInputModel } from "inputModels";
import { CompanyUserController } from "controllers";

const PAGE_SIZE = 15;
const CompanyUserContext = createContext<CompanyUserContextProps>(
  {} as CompanyUserContextProps
);

const CompanyUser: React.FC<Props> = ({ children }) => {
  const { user, logged } = useAuth();
  const [orderBy, setOrder] = useState<IOrderFieldInputModel<Columns>[]>([]);
  const [companyUsers, setCompanyUsers] = useState<IUserViewModel[]>([]);
  const [filter, setFilter] = useState<FilterProps>();

  const {
    data: results,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isLoading,
  } = useInfiniteQuery(
    ["companyUsers", orderBy, filter],
    ({ pageParam = 1 }) =>
      CompanyUserController.getByCompanyId({
        orderBy,
        page: pageParam,
        take: 15,
        term: filter?.term,
        filter: filter?.term ? filter?.filter : undefined,
      }).then((res) => res.data),
    {
      enabled: !!user?.id && logged,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < PAGE_SIZE ? undefined : nextPage;
      },
    }
  );

  const handleFetch = () => {
    refetch();
  };

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  useEffect(() => {
    setCompanyUsers(results?.pages?.flat() ?? []);
  }, [results, setCompanyUsers]);

  return (
    <CompanyUserContext.Provider
      value={{
        companyUsers,
        setCompanyUsers,
        orderBy,
        setOrder,
        filter,
        setFilter,
        handleFetch,
        handleFetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
      }}
    >
      {children}
    </CompanyUserContext.Provider>
  );
};

export const useCompanyUser: () => CompanyUserContextProps = () =>
  useContext(CompanyUserContext);

export default CompanyUser;
