import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { AppBar, Modals } from "components";
import {
  BarGraph,
  CardContainer,
  CardHeader,
  EmptyContainer,
  HistoryGraph,
} from "./styles";
import KpiCard from "components/KpiCard";
import InsightsIcon from "@mui/icons-material/Insights";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { useFontMonitoring } from "contexts/fontMonitoring";
import { useEffect, useMemo, useState } from "react";
import {
  IFontReportViewModel,
  IFontStatusHistoryViewModel,
  IFontTestViewModel,
  IHistoryTestDataViewModel,
} from "viewModels";
import {
  ConfigProvider,
  DatePickerProps,
  Empty,
  TimeRangePickerProps,
} from "antd";
import { FontMonitoringController } from "controllers/FontMonitoringController";
import moment from "moment";
import {
  Download,
  KeyboardDoubleArrowDown,
  Visibility,
} from "@mui/icons-material";
import { FontStatus, FontTestStatus } from "types/enums";
import { Bar, DualAxes } from "@ant-design/plots";
import dayjs, { Dayjs } from "dayjs";
import { useAlert } from "contexts";
import { DatePicker, Space } from "antd";
import locale from "antd/locale/pt_BR";
import { RangePickerProps } from "antd/es/date-picker";
import TimerDisplay from "components/TimerDisplay";

const { RangePicker } = DatePicker;

const FontsReport = () => {
  const alert = useAlert();
  const theme = useTheme();
  const [currentReport, setCurrentReport] = useState<IFontTestViewModel>();
  const [showSummaryTest, setShowSummaryTest] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
  const [openModalDetais, setOpenModalDetais] = useState(false);
  const [selectedFont, setSelectedFont] =
    useState<IFontStatusHistoryViewModel | null>(null);
  const {
    reports,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    handleFetchNextPage,
    testHistory,
    isLoadingTestHistory,
    fontStatusHistory,
    isLoadingFontsHistory,
    startHistoryDate,
    endHistoryDate,
    setEndHistoryDate,
    setStartHistoryDate,
    startFontDate,
    endFontDate,
    setEndFontDate,
    setStartFontDate,
  } = useFontMonitoring();
  const [lastCompletedReport, setLastCompletedReport] =
    useState<IFontTestViewModel | null>(null);

  useEffect(() => {
    if (!reports || reports.length === 0) {
      setLastCompletedReport(null);
      return;
    }

    const completedReport = reports
      .filter(
        (report: IFontTestViewModel) =>
          report.status === FontTestStatus.COMPLETED && report.createdAt
      )
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .at(0);

    setLastCompletedReport(completedReport || null);
  }, [reports]);

  const trueStatusCount = useMemo(() => {
    return (
      lastCompletedReport?.fonts?.filter(
        (font: IFontReportViewModel) => font.status === FontStatus.AVAILABLE
      ).length || 0
    );
  }, [lastCompletedReport]);

  const falseStatusCount = useMemo(() => {
    return (
      lastCompletedReport?.fonts?.filter(
        (font: IFontReportViewModel) => font.status === FontStatus.UNAVAILABLE
      ).length || 0
    );
  }, [lastCompletedReport]);

  const statusLabel = (status: FontTestStatus) => {
    switch (status) {
      case FontTestStatus.FAILED:
        return "Falha";

      case FontTestStatus.PENDING:
        return "Pendente";

      case FontTestStatus.PROCESSING:
        return "Processando";

      case FontTestStatus.COMPLETED:
        return "Finalizado";

      default:
        return "Falha";
    }
  };

  const handleTriggerReport = () => {
    setIsDisabled(true);

    const processingReport = reports.find(
      (report) => report.status === FontTestStatus.PROCESSING
    );
    if (processingReport) {
      alert.show({
        type: "alert",
        title: `Erro`,
        timeout: 2000,
        description:
          "Já existe um relátorio em processamento, aguarde sua finalização.",
      });
    } else {
      FontMonitoringController.triggerReport();
    }

    setTimeout(() => {
      setIsDisabled(false);
    }, 10000);
  };

  const formatTestHistoryDates = (data: IHistoryTestDataViewModel[]) => {
    return testHistory.map((item) => ({
      Data: dayjs(item.date).toDate(),
      Disponíveis: item.availableCount,
      Indisponíveis: item.unavailableCount,
    }));
  };

  const handleFontClick = (event: any) => {
    if (event.data) {
      const { data } = event.data;
      const font = fontStatusHistory.find((f) => f.fontKey === data.Key);
      if (font) {
        setSelectedFont(font);
        setOpenModalDetais(true);
      }
    }
  };

  const convertToBarChartData = (data: IFontStatusHistoryViewModel[]) => {
    return data.map((font) => {
      const availableCount = font.statusHistory.filter(
        (status) => status.status === FontStatus.AVAILABLE
      ).length;

      const totalCount = font.statusHistory.length;

      const successPercentage = availableCount / totalCount;

      return {
        Nome: font.fontName,
        Rate: successPercentage,
        Key: font.fontKey,
        successCount: availableCount,
      };
    });
  };

  // Metodo que exporta a planilha com os dados dos relatorios
  const handleExportReports = () => {
    setIsLoadingReport(true);
    FontMonitoringController.exportReports().finally(() =>
      setIsLoadingReport(false)
    );
  };

  const onChangeTestHistoryDate = (
    value: DatePickerProps["value"] | RangePickerProps["value"]
  ) => {
    if (Array.isArray(value)) {
      const [start, end] = value;
      if (start && end) {
        setStartHistoryDate(start);
        setEndHistoryDate(end);
      }
    } else {
      if (value) {
        setStartHistoryDate(value);
      } else {
        setStartHistoryDate(dayjs());
      }
    }
  };

  const onChangeFontsHistoryDate = (
    value: DatePickerProps["value"] | RangePickerProps["value"]
  ) => {
    if (Array.isArray(value)) {
      const [start, end] = value;
      if (start && end) {
        setStartFontDate(start);
        setEndFontDate(end);
      }
    } else {
      if (value) {
        setStartFontDate(value);
      } else {
        setStartFontDate(dayjs());
      }
    }
  };

  //Configuracao do grafico de linha
  const config = {
    data: formatTestHistoryDates(testHistory),
    xField: "Data",
    children: [
      {
        type: "area",
        yField: "Disponíveis",
        shapeField: "smooth",
        style: {
          fill: "rgba(91, 249, 117, 0.3)",
          stroke: "#5AD8A6",
          lineWidth: 2,
        },
        axis: {
          y: {
            position: "left",
            title: "Disponíveis",
            style: { titleFill: "#5AD8A6" },
          },
        },
      },
      {
        type: "area",
        yField: "Indisponíveis",
        shapeField: "smooth",
        style: {
          fill: "rgba(237, 21, 21, 0.3)",
          stroke: "#db0f0f",
          lineWidth: 2,
        },
        axis: {
          y: {
            position: "right",
            title: "Indisponíveis",
            style: { titleFill: "#db0f0f" },
          },
        },
      },
    ],
  };

  // Configuracao do grafico de barras
  const barConfig = {
    data: convertToBarChartData(fontStatusHistory),
    xField: "Nome",
    yField: "Rate",

    sort: {
      reverse: true,
    },
    label: {
      text: "Rate",
      formatter: ".1%",
      style: {
        textAlign: (d: any) => (+d.Rate > 0.008 ? "right" : "start"),
        fill: (d: any) => (+d.Rate > 0.008 ? "#fff" : "#000"),
        dx: (d: any) => (+d.Rate > 0.008 ? -5 : 5),
      },
    },
    xAxis: {
      label: {
        rotate: -45,
        style: {
          textAlign: "center",
        },
      },
    },
    meta: {
      Nome: {
        alias: "Fonte",
      },
      Rate: {
        alias: "Taxa de Sucesso (%)",
        min: 0,
        max: 100,
      },
    },

    responsive: true,
    interactions: [
      {
        type: "element-active",
      },
    ],

    onReady: (plot: any) => {
      plot.on("click", handleFontClick);
    },
  };

  // Presets para exibir no seletor de Data
  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Últimos 7 Dias", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Últimos 14 Dias", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Último mês", value: [dayjs().add(-1, "M"), dayjs()] },
    { label: "Últimos 3 meses", value: [dayjs().add(-3, "M"), dayjs()] },
    { label: "Últimos 6 meses", value: [dayjs().add(-6, "M"), dayjs()] },
  ];

  return (
    <>
      <AppBar />
      <Container
        sx={{
          minWidth: "90vw",
          minHeight: "100vh",
          pt: 10,
          pb: 4,
        }}
      >
        <Paper sx={{ p: 3, mb: 2 }}>
          <Typography component="p" variant="h4" color="primary">
            Insights do relátorio mais recente
          </Typography>
          {isLoading ? (
            <CardContainer>
              <div className="cards">
                {Array.from({ length: 4 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    sx={{
                      height: 110,
                      minWidth: "200px",
                      maxWidth: "300px",
                      margin: "10px",
                      flex: "1 1 calc(20% - 40px)",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                ))}
              </div>
              <div className="buttons">
                <Button
                  disabled
                  className="start-report"
                  variant="contained"
                  color="primary"
                >
                  Iniciar varredura
                </Button>
              </div>
            </CardContainer>
          ) : (
            <>
              {reports && reports.length > 0 ? (
                <CardContainer>
                  <div className="cards">
                    <KpiCard
                      title="Fontes analisadas"
                      value={lastCompletedReport?.fonts?.length ?? 0}
                      icon={<FormatListBulletedIcon />}
                    />
                    <KpiCard
                      title="Fontes Funcionais"
                      value={trueStatusCount}
                      icon={<ChecklistIcon />}
                      backgroundColor="#167a12"
                    />
                    <KpiCard
                      title="Fontes Inativas"
                      value={falseStatusCount}
                      icon={<PlaylistRemoveIcon />}
                      backgroundColor="#af1a3a"
                    />

                    <KpiCard
                      title="Percentual de Fontes Ativas"
                      value={
                        lastCompletedReport?.fonts?.length
                          ? (trueStatusCount /
                              lastCompletedReport.fonts.length) *
                            100
                          : 0
                      }
                      percent
                      icon={<InsightsIcon />}
                      backgroundColor="#107066"
                    />
                  </div>

                  <div className="buttons">
                    <Button
                      disabled={isDisabled}
                      className="start-report"
                      variant="contained"
                      color="primary"
                      onClick={handleTriggerReport}
                    >
                      Iniciar verificação
                    </Button>
                  </div>
                </CardContainer>
              ) : (
                <EmptyContainer>
                  <Empty
                    description={
                      "Nenhum relátorio encontrado, crie um para exibir os insights mais recentes"
                    }
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                  <Button
                    disabled={isDisabled}
                    className="start-report"
                    variant="contained"
                    color="primary"
                    onClick={handleTriggerReport}
                  >
                    Iniciar verificação
                  </Button>
                </EmptyContainer>
              )}
            </>
          )}
        </Paper>
        <Paper sx={{ p: 3, mb: 2 }}>
          <CardHeader>
            <Typography component="p" variant="h4" color="primary">
              Disponibilidade de fontes
            </Typography>
            <ConfigProvider
              locale={locale}
              theme={{
                token: {
                  colorPrimary: "#293778",
                },
              }}
            >
              <RangePicker
                format={"DD/MM/YYYY"}
                value={[startHistoryDate, endHistoryDate]}
                presets={rangePresets}
                maxDate={dayjs()}
                onChange={onChangeTestHistoryDate}
              />
            </ConfigProvider>
          </CardHeader>

          {!isLoadingTestHistory ? (
            testHistory.length > 0 ? (
              <HistoryGraph>
                <DualAxes {...config} />
              </HistoryGraph>
            ) : (
              <Empty
                description={
                  "Nenhum relátorio encontrado, crie um para exibir a disponibilidade de Fontes"
                }
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )
          ) : (
            <Skeleton
              sx={{ height: 300 }}
              animation="wave"
              variant="rectangular"
            />
          )}
        </Paper>
        <Paper sx={{ p: 3, mb: 2 }}>
          <CardHeader>
            <Typography component="p" variant="h4" color="primary">
              Taxa de sucesso
            </Typography>
            <ConfigProvider
              locale={locale}
              theme={{
                token: {
                  colorPrimary: "#293778",
                },
              }}
            >
              <RangePicker
                format={"DD/MM/YYYY"}
                value={[startFontDate, endFontDate]}
                presets={rangePresets}
                maxDate={dayjs()}
                onChange={onChangeFontsHistoryDate}
              />
            </ConfigProvider>
          </CardHeader>

          {!isLoadingFontsHistory ? (
            fontStatusHistory.length > 0 ? (
              <BarGraph>
                <Bar {...barConfig} />
              </BarGraph>
            ) : (
              <Empty
                description={
                  "Nenhum relátorio encontrado, crie um para exibir a Taxa de sucesso das Fontes."
                }
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )
          ) : (
            <Skeleton
              sx={{ height: 2000 }}
              animation="wave"
              variant="rectangular"
            />
          )}
        </Paper>
        <Paper sx={{ p: 3, mb: 2 }}>
          <CardHeader>
            <Typography component="p" variant="h4" color="primary">
              Relátorios
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExportReports}
              startIcon={
                isLoadingReport ? <CircularProgress size={20} /> : <Download />
              }
              disabled={isLoadingReport}
            >
              {isLoadingReport ? "Exportando..." : "Exportar Relatórios"}
            </Button>
          </CardHeader>

          <Table stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  th: {
                    backgroundColor: "white",
                    "& p": {
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    },
                    "& div": {
                      display: "flex",
                      alignItems: "center",
                      "& button": { ml: 1 },
                    },
                  },
                }}
              >
                <TableCell>
                  <Typography>#</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography>Id</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Data</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Status</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Razão</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Progresso</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Tempo</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>Detalhes</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <CircularProgress size={15} />
                    <Typography
                      paddingLeft={1}
                      variant="caption"
                      color="text"
                      sx={{ fontStyle: "italic" }}
                    >
                      Carregando lista de relatórios...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : !reports.length ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <Typography
                      paddingLeft={1}
                      variant="caption"
                      color="text"
                      sx={{ fontStyle: "italic" }}
                    >
                      Nenhum relatório encontrado...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                reports.map((report: IFontTestViewModel, idx) => (
                  <>
                    <TableRow
                      data-testid="rowFinancial"
                      key={report.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">
                        <Typography color="primary">
                          {(idx + 1).toString().padStart(2, "000")}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>{report.id}</Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Typography>
                          {moment(report.createdAt).format("DD/MM/yyyy HH:mm")}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{statusLabel(report.status)}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>
                          {
                            report.fonts?.filter(
                              (font: IFontReportViewModel) =>
                                font.status === FontStatus.AVAILABLE
                            ).length
                          }
                          /
                          {
                            report.fonts?.filter(
                              (font: IFontReportViewModel) =>
                                font.status === FontStatus.UNAVAILABLE
                            ).length
                          }
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {report.status === FontTestStatus.PROCESSING ? (
                          <Typography>{report.progress.toFixed(1)}%</Typography>
                        ) : (
                          <>-</>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <TimerDisplay
                          live={report.status === FontTestStatus.PROCESSING}
                          timeInSeconds={report.executionTimeInSeconds || 0}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          disabled={
                            report.status === FontTestStatus.PENDING ||
                            report.status === FontTestStatus.FAILED
                          }
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            setCurrentReport(report);
                            setShowSummaryTest(true);
                          }}
                        >
                          <Visibility />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
              {hasNextPage && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    {isFetchingNextPage ? (
                      <Grid
                        item
                        alignItems="center"
                        sx={{ minHeight: "65px !important" }}
                      >
                        <CircularProgress size={15} />
                        <Typography
                          paddingLeft={1}
                          variant="caption"
                          color="text"
                          sx={{ fontStyle: "italic" }}
                        >
                          Carregando itens...
                        </Typography>
                      </Grid>
                    ) : (
                      <Button
                        data-testid="buttonNextPage"
                        onClick={() => handleFetchNextPage()}
                        sx={{ px: 2 }}
                      >
                        <KeyboardDoubleArrowDown />
                        <Typography paddingLeft={2} variant="button">
                          Carregar mais...
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Container>
      {showSummaryTest && currentReport && (
        <Modals.SummaryTestModal
          onClose={() => setShowSummaryTest(false)}
          open={showSummaryTest}
          report={currentReport}
        />
      )}
      {openModalDetais && selectedFont && (
        <Modals.FontStatusDetailsModal
          onClose={() => setOpenModalDetais(false)}
          open={openModalDetais}
          history={selectedFont}
        />
      )}
    </>
  );
};

export default FontsReport;
