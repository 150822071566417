import { Cancel, LabelImportant, Search } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Modal } from "components";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { ModalProps } from "..";
import {
  IAvailableExternalFontValueViewModel,
  IModelDossierViewModel,
} from "viewModels";
import { useAlert, useModel } from "contexts";
import { SearchType } from "types/enums";
import { v4 as uuid } from "uuid";
import { Controller, useForm } from "react-hook-form";
import { CircleCheck, Circle } from "lucide-react";
import shadows from "@mui/material/styles/shadows";
import { Header, LabelItems } from "./styles";
import { AnimatePresence, motion } from "framer-motion";

interface SelectExternalFontsProps extends Omit<ModalProps, "onSelect"> {
  externalFonts?: {
    key: string;
    value: IAvailableExternalFontValueViewModel;
  }[];
  onSelect?: (selected: string[]) => void;
  onCreateModelAndGenerateDossier?: (
    modelName: string,
    selectedFonts: string[]
  ) => void;
  externalFontsSelecteds?: string[];
  type: "cpf" | "cnpj" | "all";
}

const SelectExternalFonts: React.FC<SelectExternalFontsProps> = ({
  externalFonts,
  onSelect,
  externalFontsSelecteds,
  onCreateModelAndGenerateDossier,
  type,
  ...props
}: SelectExternalFontsProps) => {
  const alert = useAlert();
  const [warningDisplayed, setWarningDisplayed] = useState<boolean>(false);
  const { models } = useModel();
  const [modelsFiltered, setModelsFiltered] = useState<
    IModelDossierViewModel[]
  >(models ?? []);
  const [modelsSelected, setModelsSelected] =
    useState<IModelDossierViewModel>();
  const [selectedValue, setSelectedValue] = useState<string>("models");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [fontsSelected, setFontsSelected] = useState<string[]>([]);
  const [generateModelOption, setGenerateModelOption] = useState<string>("no");
  const [defaultValues] = useState({ filter: "", modelName: "" });

  const pageVariants = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 50 },
  };

  const allFonts = useMemo(
    () => externalFonts?.map((e) => e.key) ?? [],
    [externalFonts]
  );

  const allSelected = useMemo(
    () => (externalFonts ?? []).length === fontsSelected.length,
    [externalFonts, fontsSelected]
  );

  const { control, watch, handleSubmit, setError, clearErrors, formState } =
    useForm({
      defaultValues: useMemo(() => defaultValues, [defaultValues]),
    });

  const { errors } = formState;

  const data = watch();

  const onSubmit = handleSubmit(async () => {
    if (generateModelOption === "yes") {
      if (!data.modelName.trim()) {
        setError("modelName", {
          type: "manual",
          message: "O nome do modelo é obrigatório.",
        });
        return;
      }

      const success = await onCreateModelAndGenerateDossier?.(
        data.modelName,
        fontsSelected
      );

      if (success) {
        props.onClose();
      }
    } else {
      handleGenerateDossier();
      props.onClose();
    }
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleGenerateDossier = () => {
    selectedValue === "fonts"
      ? onSelect?.(fontsSelected)
      : modelsSelected && onSelect?.(modelsSelected.fonts.map((f) => f.key));
    setFontsSelected(allFonts);
    setModelsSelected(undefined);
    setSelectedValue("fonts");
  };

  const handleSelect = (item: string) => () => {
    if (item === "all") {
      setFontsSelected(allFonts);

      alert.show({
        type: "alert",
        title: "Atenção:",
        description:
          "Selecionar mais que 20 fontes gera custo adicional na geração de dossiês.",
        timeout: 10000,
      });
      setWarningDisplayed(true);
    } else if (item === "clear") {
      if (warningDisplayed && fontsSelected.length <= 20) {
        setWarningDisplayed(false);
      }
      setFontsSelected([]);
    } else
      setFontsSelected((prev) => {
        const newSelected = [...prev];
        const idx = newSelected.indexOf(item);
        if (idx !== -1) newSelected.splice(idx, 1);
        else newSelected.push(item);

        if (fontsSelected.length >= 20 && !warningDisplayed) {
          alert.show({
            type: "alert",
            title: "Atenção:",
            description:
              "Selecionar mais que 20 fontes gera custo adicional na geração de dossiês.",
            timeout: 10000,
          });
          setWarningDisplayed(true);
        }

        if (warningDisplayed && fontsSelected.length <= 20) {
          setWarningDisplayed(false);
        }
        return newSelected;
      });
  };

  const handleSelectItem = (item: IModelDossierViewModel) => () => {
    setModelsSelected(() => {
      const newSelected = modelsSelected?.id === item.id ? undefined : item;

      if (item.fonts.length > 20 && modelsSelected?.id !== item.id) {
        alert.show({
          type: "alert",
          title: "Atenção:",
          description:
            "O modelo possui mais que 20 fontes. Selecionar ele gera custo adicional na geração de dossiês.",
          timeout: 10000,
        });
      }
      return newSelected;
    });
  };

  const handleSelectOption = (event: ChangeEvent<HTMLInputElement>) => {
    setGenerateModelOption(event.target.value);
  };

  useEffect(() => {
    setFontsSelected([]);
  }, [allFonts]);

  useEffect(() => {
    if (externalFontsSelecteds) setFontsSelected(externalFontsSelecteds);
  }, [externalFontsSelecteds]);

  useEffect(() => {
    if (type === "cnpj") {
      setModelsFiltered(models?.filter((m) => m.type !== SearchType.CPF) ?? []);
    } else if (type === "cpf") {
      setModelsFiltered(
        models?.filter((m) => m.type !== SearchType.CNPJ) ?? []
      );
    } else {
      setModelsFiltered(models ?? []);
    }
  }, [models, type]);

  useEffect(() => {
    const filteredModels =
      models?.filter((model) =>
        model.name.toLowerCase().includes(data.filter.toLowerCase())
      ) ?? [];
    setModelsFiltered(filteredModels);
  }, [data.filter, models]);

  return (
    <Modal
      data-testid="external-fonts-mock"
      open={props.open}
      onClose={props.onClose}
      style={{
        overflow: "hidden",
      }}
    >
      <Header>
        <IconButton size="small" onClick={props.onClose} color="error">
          <Cancel />
        </IconButton>
      </Header>
      <AnimatePresence mode="wait">
        {currentPage === 1 && (
          <motion.div
            key="page1"
            variants={pageVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.1 }}
          >
            <Grid2 container justifyContent="space-between">
              <Typography variant="h6" component="h2" color={"primary"}>
                {type !== "all"
                  ? "Selecione o tipo para gerar o dossiê:"
                  : "Selecione as fontes"}
              </Typography>
            </Grid2>
            {type !== "all" && (
              <Grid2 display="flex" justifyContent="center">
                <FormControl component="fieldset">
                  <RadioGroup onChange={handleChange} value={selectedValue}>
                    <Grid2 display="flex" flexDirection="row" gap={3}>
                      <FormControlLabel
                        value="models"
                        control={<Radio />}
                        label="Modelos"
                      />
                      <FormControlLabel
                        value="fonts"
                        control={<Radio />}
                        label="Fontes"
                      />
                    </Grid2>
                  </RadioGroup>
                </FormControl>
              </Grid2>
            )}
            {selectedValue === "models" && (
              <Grid2>
                <Grid2 display="flex" justifyContent="end">
                  <Controller
                    control={control}
                    name="filter"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        size="small"
                        label="Pesquisar Modelos"
                        variant="standard"
                        type="search"
                        autoComplete="off"
                        placeholder="O que deseja buscar?"
                        sx={{ width: 170 }}
                        onChange={onChange}
                        value={value}
                        InputProps={{
                          sx: {
                            pl: 1,
                          },
                          endAdornment: <Search />,
                        }}
                      />
                    )}
                  />
                </Grid2>
                <Typography variant="h6" component="h2" color={"primary"}>
                  Selecione um modelo:
                </Typography>
                {modelsFiltered.length > 0 ? (
                  <List dense sx={{ maxHeight: "40vh", overflowY: "auto" }}>
                    {modelsFiltered
                      ?.filter((item) => !item.disabled)
                      .map((item) => (
                        <ListItem
                          data-testid="listModels"
                          key={item.id}
                          onClick={handleSelectItem(item)}
                          sx={{
                            transition: "all 0.5s ease-in-out",
                            cursor: "pointer",
                            borderRadius: 1,
                            userSelect: "none",
                            ":hover": {
                              backgroundColor: "rgba(228, 228, 228, 0.05)",
                            },
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: 30 }}>
                            <LabelImportant
                              color={
                                modelsSelected?.id === item.id
                                  ? "warning"
                                  : "inherit"
                              }
                            />
                          </ListItemIcon>
                          <ListItemText primary={item.name} />
                        </ListItem>
                      ))}
                  </List>
                ) : (
                  <Grid2>
                    <Typography variant="body1" align="center" color="grey">
                      Nenhum modelo encontrado para o tipo de documento do
                      dossiê...
                    </Typography>
                  </Grid2>
                )}
              </Grid2>
            )}
            {selectedValue === "fonts" && (
              <Grid2>
                <Grid2 display="flex" justifyContent="end">
                  <Controller
                    control={control}
                    name="filter"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        size="small"
                        label="Pesquisar Fontes"
                        variant="standard"
                        type="search"
                        autoComplete="off"
                        placeholder="O que deseja buscar?"
                        sx={{ width: 170 }}
                        onChange={onChange}
                        value={value}
                        InputProps={{
                          sx: {
                            pl: 1,
                          },
                          endAdornment: <Search />,
                        }}
                      />
                    )}
                  />
                </Grid2>
                <Grid2>
                  <Typography variant="h6" component="h2" color={"primary"}>
                    Relação de documentos:
                  </Typography>
                </Grid2>
                <Grid2
                  data-testid="Grid2MarcarDesmarcar"
                  container
                  justifyContent="space-between"
                  sx={{ my: 1 }}
                >
                  <Typography sx={{ whiteSpace: "pre-line" }}>
                    Clique para marcar/desmarcar
                  </Typography>
                  {/* <Button
                  size="small"
                  onClick={handleSelect(allSelected ? "clear" : "all")}
                  color="primary"
                >
                  {allSelected ? "Desmarcar todos" : "Selecionar tudo"}
                </Button> */}
                </Grid2>
                <List
                  dense
                  sx={{
                    maxHeight: "50vh",
                    overflowY: "auto",
                    padding: "10px",
                  }}
                >
                  {externalFonts
                    ?.filter((f) =>
                      data.filter
                        ? f.value.name
                            .toLocaleLowerCase()
                            .includes(data.filter.toLocaleLowerCase())
                        : externalFonts
                    )
                    .map((item) => (
                      <ListItem
                        data-testid="listFonts"
                        key={item.key + uuid()}
                        onClick={handleSelect(item.key)}
                        sx={{
                          transition: "all 0.5s ease-in-out",
                          cursor: "pointer",
                          marginBottom: "15px",
                          borderRadius: 1,
                          userSelect: "none",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                          border: "2px solid transparent",
                          backgroundColor: fontsSelected.includes(item.key)
                            ? "rgba(0, 0, 0, 0.100)"
                            : "white",
                          ":hover": {
                            border: "2px solid #293778",
                            boxShadow: shadows[4],
                          },
                        }}
                      >
                        {fontsSelected.includes(item.key) ? (
                          <CircleCheck
                            color="#009b1a"
                            data-testid="iconFont"
                            size={"20px"}
                          />
                        ) : (
                          <Circle data-testid="iconFont" size={"20px"} />
                        )}
                        <LabelItems>{item.value.name}</LabelItems>
                      </ListItem>
                    ))}
                </List>
              </Grid2>
            )}
            <Grid2
              container
              spacing={2}
              sx={{
                mt: 2,
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Grid2>
                <Button
                  disabled={
                    selectedValue === "fonts"
                      ? !fontsSelected.length
                      : !modelsSelected
                  }
                  sx={{ width: 150, whiteSpace: "nowrap" }}
                  variant="outlined"
                  onClick={() => {
                    if (selectedValue === "models") {
                      handleGenerateDossier();
                      props.onClose();
                    } else {
                      setCurrentPage(2);
                    }
                  }}
                >
                  {selectedValue === "models" ? "Gerar Dossiê" : "Próximo"}
                </Button>
              </Grid2>
            </Grid2>
          </motion.div>
        )}
        {currentPage === 2 && (
          <motion.div
            key="page2"
            variants={pageVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.1 }}
          >
            <Typography variant="h6" gutterBottom>
              Deseja gerar um modelo com as fontes selecionadas?
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                value={generateModelOption}
                onChange={handleSelectOption}
                row
              >
                <FormControlLabel value="yes" control={<Radio />} label="Sim" />
                <FormControlLabel value="no" control={<Radio />} label="Não" />
              </RadioGroup>
            </FormControl>
            {generateModelOption === "yes" && (
              <Controller
                name="modelName"
                control={control}
                rules={{
                  required: "O nome do modelo é obrigatório.",
                  validate: (value) =>
                    value.trim() !== "" ||
                    "O nome do modelo não pode estar vazio.",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Nome do Modelo"
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2 }}
                    error={!!errors.modelName}
                    helperText={errors.modelName?.message}
                    onFocus={() => clearErrors("modelName")}
                  />
                )}
              />
            )}
            <Grid2
              container
              spacing={2}
              sx={{
                mt: 2,
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Grid2>
                <Button
                  sx={{ width: 100, whiteSpace: "nowrap" }}
                  onClick={() => setCurrentPage(1)}
                >
                  Voltar
                </Button>
              </Grid2>
              <Grid2>
                <Button
                  disabled={
                    (selectedValue === "fonts" && !fontsSelected.length) ||
                    (selectedValue === "models" && !modelsSelected) ||
                    (generateModelOption === "yes" &&
                      !watch("modelName").trim())
                  }
                  sx={{ width: 150, whiteSpace: "nowrap" }}
                  variant="outlined"
                  onClick={onSubmit}
                >
                  {generateModelOption === "yes"
                    ? "Criar e Gerar"
                    : "Gerar Dossiê"}
                </Button>
              </Grid2>
            </Grid2>
          </motion.div>
        )}
      </AnimatePresence>
    </Modal>
  );
};

export default SelectExternalFonts;
