import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: end;
`;
export const LabelItems = styled.div`
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 3.4;
  letter-spacing: 0.01071rem;
  width: 100%;
  display: block;
  margin-left: 8px;
`;
