import {
  ICollaboratorUserInputModel,
  IOrderedWithPaginationInputModel,
  IUpdateCollaboratorUserInputModel,
  IUpdatePasswordCollaboratorUserInputModel,
} from "inputModels";
import api from "services/api";
import {
  ICollaboratorUserInfosViewModel,
  ICollaboratorUserViewModel,
  IResponse,
  IVoidResponse,
} from "viewModels";

const updateDisableStatusCollaborator = (id: string, status: boolean) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`collaboratoruser/${id}`, status, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const insert = (input: ICollaboratorUserInputModel) =>
  new Promise<IResponse<string>>((resolve, reject) => {
    api
      .post(`collaboratoruser`, input)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getAll = () =>
  new Promise<IResponse<ICollaboratorUserViewModel[]>>((resolve, reject) => {
    api
      .get(`collaboratoruser`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
const getCollaboratorInfoById = () =>
  new Promise<IResponse<ICollaboratorUserInfosViewModel>>((resolve, reject) => {
    api
      .get(`collaboratoruser/get-collaborator-infos`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getCollaboratorByUserId = <T>(
  userId: string,
  input?: IOrderedWithPaginationInputModel<T>
) =>
  new Promise<IResponse<ICollaboratorUserViewModel[]>>((resolve, reject) => {
    api
      .post(`collaboratoruser/get-collaborator-by-user-id/${userId}`, input, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const updateUser = (update: IUpdateCollaboratorUserInputModel) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`collaboratoruser`, update)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const updatePasswordUser = (
  update: IUpdatePasswordCollaboratorUserInputModel
) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`collaboratoruser/update-password`, update, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const verifyAlreadyEmail = (email: string) =>
  new Promise<IResponse<boolean>>((resolve, reject) => {
    api
      .get(`collaboratoruser/${email}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const CollaboratorUserController = {
  updateDisableStatusCollaborator,
  insert,
  getAll,
  getCollaboratorByUserId,
  getCollaboratorInfoById,
  updateUser,
  updatePasswordUser,
  verifyAlreadyEmail,
};
