import {
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Modal, { ModalProps } from "..";
import { Cancel } from "@mui/icons-material";
import { IDossierViewModel } from "viewModels";
import { Container, ModalContent, ModalHeader } from "./styles";
import { useEffect, useState } from "react";
import { useDossier } from "contexts";

interface SummaryDossierProps extends ModalProps {
  dossier: IDossierViewModel;
}

const SummaryDossier: React.FC<SummaryDossierProps> = ({
  dossier,
  ...props
}: SummaryDossierProps) => {
  const theme = useTheme();
  const { getDossierSummary, loadingDossierSummary } = useDossier();
  const [localDossier, setLocalDossier] = useState<IDossierViewModel>(dossier);

  useEffect(() => {
    const fetchSummary = async () => {
      if (props.open && !dossier.summaryDossier) {
        const summary = await getDossierSummary(dossier.id);
        if (summary) {
          setLocalDossier((prev) => ({ ...prev, summaryDossier: summary }));
        }
      }
    };

    fetchSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, dossier]);

  return (
    <Modal
      {...props}
      sx={{
        width: "80vw",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ModalHeader>
        <Typography variant="h6" component="h2" color={"primary"}>
          Resumo do dossiê <b>{dossier.id}</b>
        </Typography>
        <IconButton size="small" onClick={props.onClose} color="error">
          <Cancel />
        </IconButton>
      </ModalHeader>
      <ModalContent>
        <Table stickyHeader aria-label="listDossiers">
          <TableHead>
            <TableRow
              sx={{
                th: {
                  backgroundColor: "white",
                  "& p": {
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  },
                  "& div": {
                    display: "flex",
                    alignItems: "center",

                    "& button": {
                      ml: 1,
                    },
                  },
                },
              }}
            >
              <TableCell sx={{ width: "80%" }}>
                <Typography>Nome da Fonte</Typography>
              </TableCell>
              <TableCell sx={{ width: "30%" }} align="center">
                <Typography>Status da Execução</Typography>
              </TableCell>
              <TableCell sx={{ width: "10%" }} align="center">
                <Typography>Pendências</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localDossier.summaryDossier?.namesFontsWithPendenciesName?.map(
              (font) => (
                <TableRow
                  data-testid="tableRowDossier"
                  key={font.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell sx={{ width: "80%" }}>
                    <Tooltip title={font.name} placement="top-start">
                      <Typography>{font.name}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ width: "10%" }} align="center">
                    <Typography>OK</Typography>
                  </TableCell>
                  <TableCell sx={{ width: "10%" }} align="center">
                    <Typography
                      style={{
                        color: theme.palette.warning.light,
                      }}
                    >
                      Sim
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            )}
            {localDossier.summaryDossier?.namesFontsWithoutPendenciesName?.map(
              (font) => (
                <TableRow
                  data-testid="tableRowDossier"
                  key={font.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell sx={{ width: "80%" }}>
                    <Tooltip title={font.name} placement="top-start">
                      <Typography>{font.name}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ width: "10%" }} align="center">
                    <Typography>OK</Typography>
                  </TableCell>
                  <TableCell sx={{ width: "10%" }} align="center">
                    <Typography
                      style={{
                        color: theme.palette.success.light,
                      }}
                    >
                      Não
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            )}
            {localDossier.summaryDossier?.namesFontsWithoutData?.map((font) => (
              <TableRow
                data-testid="tableRowDossier"
                key={font.name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell sx={{ width: "80%" }}>
                  <Tooltip title={font.name} placement="top-start">
                    <Typography>{font.name}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: "10%" }} align="center">
                  <Typography
                    style={{
                      color: theme.palette.error.light,
                    }}
                  >
                    Erro - Fonte com possíveis falhas
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "10%" }} align="center">
                  <Typography>-</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loadingDossierSummary && (
          <div className="loading">
            <CircularProgress size={20} />
            <Typography
              paddingLeft={1}
              variant="caption"
              color="text"
              sx={{ fontStyle: "italic" }}
            >
              Carregando Sumário...
            </Typography>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SummaryDossier;
