import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";

interface TimerDisplayProps {
  timeInSeconds: number; // Tempo inicial recebido da API
  live?: boolean; // Controla se o timer deve continuar incrementando
}

const TimerDisplay: React.FC<TimerDisplayProps> = ({
  timeInSeconds,
  live = false,
}) => {
  const [time, setTime] = useState<number>(timeInSeconds);

  // Função para formatar o tempo em horas:minutos:segundos
  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600); // Calcular as horas
    const mins = Math.floor((seconds % 3600) / 60); // Calcular os minutos
    const secs = Math.floor(seconds % 60); // Calcular os segundos
    if (hours > 0) {
      return `${hours}:${mins < 10 ? "0" + mins : mins}:${
        secs < 10 ? "0" + secs : secs
      }`;
    } else {
      return `${mins}:${secs < 10 ? "0" + secs : secs}`;
    }
  };

  // Sincronizar o estado local com timeInSeconds quando ele mudar
  useEffect(() => {
    setTime(timeInSeconds);
  }, [timeInSeconds]);

  // Incrementar o tempo localmente se live estiver ativo
  useEffect(() => {
    if (live) {
      const interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [live]);

  return (
    <div style={{ width: "100%", justifyContent: "center" }}>
      <Typography>
        {time !== null ? <>{formatTime(time)}</> : <>-</>}
      </Typography>
    </div>
  );
};

export default TimerDisplay;
